'use strict';


angular.module('kljDigitalLibraryApp')

.controller('OrderDetailCtrl', ["$scope", "$rootScope", "$state", "ngToast", "User", "OrderService", "SubscriptionService",
    function($scope, $rootScope, $state, ngToast, User, OrderService, SubscriptionService) {

        $rootScope.title = 'Order Details'
    }
]);